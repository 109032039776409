<template>
  <div v-if="props.totalIndices > 1" class="flex justify-center pt-4 space-x-2">
    <span
      v-for="n in props.totalIndices"
      :key="n"
      class="w-2 h-2 rounded-full"
      :class="activeIndex === n ? 'bg-iq-red' : 'bg-iq-beige'"
    />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  activeIndex: number;
  totalIndices: number;
}>();
</script>
